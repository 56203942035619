/* ----------------- LANDING LANZAMIENTO ----------------- */

/* ------- Componente 1 - Pantalla princial ------- */
.pantalla-principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 10px;
    margin: 0 30px;
  }
  
  .content-principal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 30px;
    width: 100%;
  }
  
  .text-principal {
    max-width: 80%;
    padding: 20px;
  }
  
  .text-principal h1 {
    font-size: 43px;
    margin-bottom: 21px;
    color: #133E79;
  }
  
  .text-principal p {
    font-size: 21px;
    margin-bottom: 20px;
    color: #848080;
  }
  
  .button-lista {
    gap: 20px;
    align-items: center;
  }
  
  .blinking-button-lista {
    padding: 10px 20px;
    background-color: #1970BE;
    border: 2px solid #1970BE;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border-radius: 15px;
    cursor: pointer;
    animation: blinkingText 1.5s infinite;
  }
  
  @keyframes blinkingText {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .conoce-softpital img {
    width: 90%; /* ajusta al contenedor */
    height: 30%; /* altura */
    object-fit: cover; 
  }  

  .text-principal h2 {
    font-size: 43px;
    margin-bottom: 21px;
    color: #133E79;
  }

  .text-principal p {
    font-size: 21px;
    margin-bottom: 20px;
    color: #848080;
  }


/* ------- Componente 2 - Mejor Opción ------------ */
/* .dashboard {
  padding: 20px;
  align-items: center;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.content h2{
  max-width: 800px;
  margin: 0 auto;
  align-items: center;
  color: #133E79;
}

.description {
  margin-bottom: 20px;
}

.description h2 {
  color: #0066CC;
}

.screenshot {
  margin: 20px 0;
  align-items: center;
}

.screenshot img {
  align-items: center;
  width: 80%;
  height: auto;
} */


/* ------- Componente 3 - Cuadros beneficios ------ */
html {
  scroll-behavior: smooth;
}

.cuadros-beneficios {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.cuadro {
  background-color: #E1EAF6;
  color: #133E79;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  width: 600px;
  height: 345px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.cuadro:hover {
  transform: translateY(-5px);
}

.cuadro h2 {
  font-size: 180%;
  margin: 20px;
  margin-bottom: 15px;
}

.cuadro p {
  color: black;
  margin: 20px;
  font-size: 16px;
}

.cuadro img {
  width: 50%;
  height: auto;
  align-items: center;
}


/* ------- Componente 4 - Banner Seguridad -------- */
.container-seguridad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 420px; 
    padding: 0px 50px;
    margin: 0px 30px; /* agrega margen en los laterales */
  }
  
  .text-seguridad {
    max-width: 80%;
    color: white;
  }
  
  .text-seguridad h1 {
    color: #133E79;
    font-size: 45px;
    margin-bottom: 10px;
  }
  
  .text-seguridad p {
    color: #848080;
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .image-section-seguridad img {
    width: 60%;
    height: auto;

  }


/* ------- Componente 5 - Banner Foto ------- */
.container-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url(../../public/bannerdemo.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 14px;
    height: 420px; 
    padding: 60px 50px;
    margin: 0 30px; /* agrega margen en los laterales */
  }
  
  .text-banner {
    max-width: 80%;
    color: white;
  }
  
  .text-banner h1 {
    color: white;
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .text-banner p {
    color: white;
    font-size: 16px;
    margin-bottom: 40px;
  }
  
  .image-section-banner img {
    max-width: 90%;
    height: auto;
  }


/* ------- Componente 6 - Banner Botones - */
.container-bannerbotones {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    background-color: #fff;
    margin: 0 30px 40px; /* agrega margen en los laterales */
  }
  
  .text-bannerbotones h2 {
    font-size: 25px;
    color: #133E79;
    margin: 0;
    max-width: 70%;
    font-size: 32px;
    font-weight:bolder;
  }
  
  .text-bannerbotones h3 {
    font-size: 28px;
    color: #000;
    margin: 5px 0;
    max-width: 70%;
    font-weight: bold;
  }
  
  /* BOTONES */
  .buttons-bannerbotones {
    top: 10px;   /* posición vertical */
    left: 0px;  /* posición horizontal */
    display: flex;
    gap: 20px;
  }
  
  .button-demo-bannerbotones, .button-talk-bannerbotones {
    padding: 10px 20px;
    background-color: transparent;
    font-size: 15px;
    color: #0087FF;
    font-weight: bold;
    border: 3px solid #0087FF;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* hover */
  }
  
  .button-demo-bannerbotones:hover, .button-talk-bannerbotones:hover { 
    background-color: #0087FF; /* hover */
    color: white; 
  }
  