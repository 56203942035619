/* ----------------- LANDING PRINCIPAL ----------------- */

/* ------- COMIENZA NAV ------- */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 70px;
  background-color: #fff;
  margin: 6px 6px 6px;
}

.logo-nav img {
  height: 40px; 
}

.menu-nav {
  display: flex;
  gap: 50px; /* espacio entre los enlaces del menú */
}

.menu-nav a {
  text-decoration: none;
  color: #A6A6A6;
  font-size: 16px;
  transition: font-weight 0.3s; /* hover */
}

.menu-nav a:hover {
  color: #133E79; 
  font-weight: bold;
}
  
  /* BOTONES */
.buttons-nav {
  top: 10px;   /* posición vertical */
  left: 0px;  /* posición horizontal */
  display: flex;
  gap: 20px;
}

.login-button-nav {
  padding: 10px 20px;
  background-color: transparent;
  color: #133E79;
  font-weight: bold;
  border: 2px solid #133E79;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* hover */
}

.login-button-nav:hover { 
  background-color: #133E79; /* hover */
  color: white; 
}
  
.demo-button-nav { 
  padding: 10px 20px;
  background-color: #133E79;
  border: 2px solid #133E79;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer; 
}

/* RESPONSIVIDAD */
@media (max-width: 929px) {
  .nav {
    align-items: center;
    padding: 10px 20px;
    display: inline-block;
  }

  .logo-nav img {
    height: 50px; 
  }

  .menu-nav {
    gap: 20px;
    margin-top: 10px;
  }

  .menu-nav a {
    font-size: 16px;
  }

  .buttons-nav {
    gap: 10px;
    margin-top: 10px;
  }

  .login-button-nav, .demo-button-nav {
    width: 100%;
  }
}
/* Termina navbar */


/* ------- COMIENZA FIRST SECTION ------- */
.first-screen {
  background-color: #133E79;
  color: white;
  text-align: left;
  height: 200%; 
  padding: 60px 20px;
  margin: 0 30px; /* agrega margen en los laterales */
  border-radius: 14px; 
  align-items: flex-end;
}

.content-first {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 70px;
}

.text-first {
  position: relative;
  top: -50px; /* posición vertical */
  left: -70px; /* posición horizontal */
  padding: 30px;
  max-width: 2000px;
}

.text-first h1 {
  font-size: 43px;
  margin-bottom: 21px;
}

.text-first p {
  white-space: normal; 
  font-size: 21px;
  margin-bottom: 20px;
}

.buttons-first {
  display: flex;
  gap: 20px;
  align-items: last baseline;
}

.blinking-button-first{
  padding: 10px 20px;
  background-color: #BFD9FC;
  border: 2px solid #BFD9FC;
  color: #133E79;
  font-weight: bold;
  font-size: 18px;
  border-radius: 15px;
  cursor: pointer;
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
  
.contact-button-firts a {
  text-decoration: none;
  color: white;
  font-size: 15px;
  transition: font-weight 0.3s; /* hover */
}
    
.contact-button-firts a:hover { 
  font-weight: bold; /* hover */
}

.sistemacomputo-img {
  position: relative; 
  top: -30px;   /* posición vertical */
  left: -5%;  /* posición horizontal */
}
    
.sistemacomputo-img img {
  max-width: 120%;
  height: auto;
}

/* RESPONSIVIDAD */
@media (max-width: 929px) {
  .content-first {
    /* flex-direction: column;
    align-items: flex-start;
    text-align: center; */
    display: inline-block;
  }

  .text-first, .buttons-first {
  /*  align-items: center;
    justify-content: center;
    text-align: center; */
    display: inline-block;
  }

  .buttons-first {
  /*  flex-direction: column;
    gap: 10px; */
    display: inline-block;
  }

  .text-first h1, .text-first p {
  /*  font-size: 1.5rem;
    text-align: center; */
    display: inline-block;
  }
}
/* Termina first section */


/* ------- COMIENZA SECOND SECTION ------- */
.second-section {
  padding: 10px 30px;
  background-image: url(../../public/secondSection.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.text-second-section {
  margin-bottom: 40px;
  text-align: left;
}

.text-second-section h2 {
  text-align: left;
  color: #133E79;
  font-size: 35px;
  font-weight: bolder;
  margin: 30px 40px 10px; 
}

.text-second-section p {
  max-width: 80%;
  white-space: normal; 
  margin: 0px 40px 0px; 
  text-align: left;
  color: black;
  font-size: 25px;
}

.statistics-second-section {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 10px 10px;
  max-width: 80%;
}

.statistics-item-second-section {
  flex: 1;
  margin: 0 10px;
  padding: 30px;
  text-align: left;
}

.statistics-item-second-section h3 {
  margin: 0 0 10px;
  font-size: 60px;
  font-weight: bold;
  color: #1970BE;
}

.statistics-item-second-section p {
  margin: 0;
  color: #848080;
  font-size: 18px;
}

/* RESPONSIVIDAD */
@media (max-width: 768px) {
  .text-second-section, .statistics-item-second-section {
    text-align: center;
    display: inline-block;
  }

  .statistics-second-section {
    flex-direction: column;
    display: inline-block;
  }

  .statistics-item-second-section {
    margin: 10px 0;
    display: inline-block;
  }

  .text-second-section h2, .text-second-section p {
    font-size: 1.5rem;
    display: inline-block;
  }
}


/* ------- COMIENZA BENEFITS ------- */
.container-benefits {
  height: 1320px; 
  background-color: white;
  text-align: left;
  padding: 20px 0px;
}

.container-benefits h1 {
  font-size: 40px;
  color: black;
  max-width: 60%;
  white-space: normal;
  margin: 0 100px 30px;
  margin-top: -1.5%;
}

/* Imagen a la izquierda 1 */
.section-benefits {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
}

.sliding-image {
  flex: 1;
  overflow: hidden;
}

.sliding-image img {
  max-width: 100%;
  height: auto;
  transform: translateX(-100vw);
  transition: transform 2s ease-in-out;
}

.sliding-image img.animate {
  transform: translateX(0);
}

.text-benefits {
  flex: 1;
  text-align: left;
  margin: 0 90px;
}

.text-benefits h4 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #133E79;
  white-space: normal;
}

.text-benefits p {
  font-size: 18px;
  color: black;
  white-space: normal;
}

/* Imagen a la derecha 2 */
.section-benefits2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
}

.sliding-image2 {
  flex: 1;
  overflow: hidden;
}

.sliding-image2 img {
  max-width: 100%;
  height: auto;
  transform: translateX(100vw);
  transition: transform 2s ease-in-out;
}

.sliding-image2 img.animate {
  transform: translateX(0);
}

.text-benefits2 {
  flex: 1;
  text-align: center;
  margin: 0px 92px;
}

.text-benefits2 h2 {
  font-size: 32px;
  text-align: right;
  margin-bottom: 20px;
  color: #133E79;
  white-space: normal;
}

.text-benefits2 p {
  font-size: 18px;
  text-align: right;
  color: black;
  white-space: normal;
}

/* Imagen a la derecha 3 */
.section-benefits3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  width: 100%;
}

.sliding-image3 {
  flex: 1;
  overflow: hidden;
}

.sliding-image3 img {
  max-width: 100%;
  height: auto;
  transform: translateX(-100vw);
  transition: transform 2s ease-in-out;
}

.sliding-image3 img.animate {
  transform: translateX(0);
}

/* Botón */
.demo-button-benefits {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 1.3%;
  margin-bottom: 1.3%;
}

.demo-benefits {
  padding: 10px 20px;
  background-color: transparent;
  border: 3px solid #0087FF;
  color: #0087FF;
  font-weight: bold;
  font-size: 21px;
  border-radius: 15px;
  cursor: pointer;
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.demo-benefits:hover {
  background-color: #0087FF;
  color: white;
}


/* ------- COMIENZA TESTIMONIALS ------- */
.testimonials {
  padding: 30px;
  margin: 50px 40px;
  background-color: white;
}

.header-testimonials h1 {
  color: #848080;
  text-align: left;
  font-size: 25px;
  margin: 0px !important;
  max-width: 70%;
}

.header-testimonials h5 {
  color: #133E79;
  text-align: left;
  font-size: 40px;
  line-height: 1.1 !important; /* interlineado */
  margin: 1em; 
  margin: 0%; 
}

.testimonial {
  padding: 30px;
  line-height: 1.2 !important; /* interlineado */
  max-width: 95%;
  text-align: center;
}

.testimonial strong {
  display: block;
  margin-top: 5px;
  color: #0056b3;
}


/* ------- COMIENZA BANNER DEMO ------- */
.container-demo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(../../public/bannerdemo.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 14px;
  height: 420px; 
  padding: 60px 50px;
  margin: 0 30px; /* agrega margen en los laterales */
}

.text-demo {
  max-width: 80%;
  color: white;
}

.text-demo h1 {
  color: white;
  font-size: 28px;
  margin-bottom: 10px;
}

.text-demo p {
  color: white;
  font-size: 16px;
  margin-bottom: 40px;
}

.image-section-demo img {
  max-width: 90%;
  height: auto;
}


/* ------- COMIENZA FAQ ------- */
.container-faq {
  padding: 10px 30px;
  margin: 20px 150px;
}

.container-faq h2 {
  font-size: 31px;
  color: #133E79;
  text-align: center;
}

.container-faq ul {
  list-style-type: none;
  padding: 0;
}

.item-faq {
  padding: 15px 0;
}

.question-faq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: black;
  font-size: 21px;
  transition: font-weight 0.3s, color 0.3s; /* hover */
}

.question-faq:hover, .question-faq.active{
  /* background-color: #BFD9FC; */
  font-weight: bolder;
  color: #0087FF;
  /* border-radius: 5px;
  padding: 10px 10px; */
}

/* SUMA */
.toggle-button-faq {
  background: none;
  border: none;
  font-size: 21px;
  cursor: pointer;
  color: #0087FF;
}

.answer-faq {
  margin-top: 10px;
  padding-left: 20px;
  color: #848080;
  font-size: 19px;
}


/* ------- COMIENZA BANNER DEMO TWO ------- */
.container-bannertwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background-color: #fff;
  margin: 0 30px 40px; /* agrega margen en los laterales */
}

.text-bannertwo h2 {
  font-size: 25px;
  color: #133E79;
  margin: 0;
  max-width: 70%;
  font-size: 32px;
  font-weight:bolder;
}

.text-bannertwo h3 {
  font-size: 28px;
  color: #000;
  margin: 5px 0;
  max-width: 70%;
  font-weight: bold;
}

/* BOTONES */
.buttons-bannertwo {
  top: 10px;   /* posición vertical */
  left: 0px;  /* posición horizontal */
  display: flex;
  gap: 20px;
}

.button-demo-bannertwo, .button-talk-bannertwo {
  padding: 10px 20px;
  background-color: transparent;
  font-size: 15px;
  color: #0087FF;
  font-weight: bold;
  border: 3px solid #0087FF;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* hover */
}

.button-demo-bannertwo:hover, .button-talk-bannertwo:hover { 
  background-color: #0087FF; /* hover */
  color: white; 
}

/* ------- COMIENZA FOOTER ------- */
.footer {
  background-color: #133E79; /* Color de fondo */
  color: white;
  padding: 40px 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 100px; 
}

.company-info-footer, .social-links-footer, .company-policies-footer {
  flex: 1;
  margin: 10px;
}

.footer-logo {
  width: 225px;
  margin-bottom: 10px;
}

.footer h1 {
  font-size: 20px;
  margin-bottom: 15px;
}

.footer p, .footer a {
  font-size: 16px;
  line-height: normal;
}

.footer a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 5px;
  transition: font-weight 0.3s; /* hover */
}

.footer a:hover {
  color: white; 
  font-weight: bold;
}

.footer-bottom-footer {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
}


/* ------- COMIENZA CARDS ------- */
html {
  scroll-behavior: smooth;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.card {
  background-color: #1e3d78;
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  width: 600px;
  height: 345px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card h2 {
  font-size: 230%;
  margin: 20px;
  margin-bottom: 15px;
}

.card a {
  color: white;
  margin: 20px;
  font-size: 22px;
  text-decoration: none;
  transition: font-weight 0.3s, color 0.3s; /* hover */
}

.card a:hover {
  font-weight: bold;
}

/* ------- POP-UP: BLOG ------- */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal.show {
  display: block;
}

.modal-content {
  background-color: white;
  margin: 10% auto; /* ajusta el margen para centrar mejor el modal */
  padding: 20px;
  border: 1px solid #BFD9FC;
  max-width: 600px; /* ajusta el máximo ancho */
  box-sizing: border-box;
  border-radius: 10px; 
}

.modal-content img {
  max-width: 100%; /* la imagen no desborde el modal */
  height: auto;
}

.modal-content h1 {
  max-width: 100%; 
  text-align: center;
  font-size: 28px;
  color: black;
}

.modal-content p {
  max-width: 100%; 
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #0087FF;
}

.close {
  color: #133E79;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  font-weight: bold;
}

/* BOTÓN FIJO SCROLL */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 1000;
}

.scroll-to-top:hover {
  opacity: 1;
}